@font-face {
font-family: '__marine_eae6b3';
src: url(/_next/static/media/0f7f4a19186d367c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__marine_eae6b3';
src: url(/_next/static/media/2108bee9c7499ea1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__marine_eae6b3';
src: url(/_next/static/media/7da500a151705acc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__marine_eae6b3';
src: url(/_next/static/media/cd4835821bb32568-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__marine_Fallback_eae6b3';src: local("Arial");ascent-override: 102.24%;descent-override: 33.46%;line-gap-override: 0.00%;size-adjust: 94.27%
}.__className_eae6b3 {font-family: '__marine_eae6b3', '__marine_Fallback_eae6b3'
}.__variable_eae6b3 {--font-marine: '__marine_eae6b3', '__marine_Fallback_eae6b3'
}

@font-face {
font-family: '__merriweather_8bc2d0';
src: url(/_next/static/media/a8ac589e441a93e5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__merriweather_8bc2d0';
src: url(/_next/static/media/7acf4cf7f953c7f2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__merriweather_8bc2d0';
src: url(/_next/static/media/0577ba954a3a3852-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__merriweather_8bc2d0';
src: url(/_next/static/media/fdf4ec13a3210a0c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__merriweather_Fallback_8bc2d0';src: local("Arial");ascent-override: 94.67%;descent-override: 26.15%;line-gap-override: 0.00%;size-adjust: 111.46%
}.__className_8bc2d0 {font-family: '__merriweather_8bc2d0', '__merriweather_Fallback_8bc2d0'
}.__variable_8bc2d0 {--font-merriweather: '__merriweather_8bc2d0', '__merriweather_Fallback_8bc2d0'
}

